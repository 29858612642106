<template>
    <div class="theory-list">
        <div class="theory-list-header" style="padding-top: 20px">
            状态
            <el-select v-model="status" style="width: 200px;margin-left: 10px" placeholder="请选择" @change="selectState">
                <el-option
                        v-for="item in stateList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                </el-option>
            </el-select>
        </div>
        <el-table class="customTable" :data="studentList"
                  style="width: 100%; margin-top: 20px; flex: 1;border: 1px solid #EEEEEE;" height="1%" size="medium"
                  :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#666666', background: '#F6F6F6',fontSize: '16px'}"
                  :cell-style="{fontSize: '16px',color: '#333333',height: '70px'}">
            <el-table-column prop="module" align="left" label="考试内容"></el-table-column>
            <el-table-column align="center" label="答案" v-if="level !== 2">
                <template slot-scope="scope">
                    <div class="goods-wrapper" v-if="scope.row.url">
                        <div class="goods-cover">
                            <img :src="scope.row.module === '用户页面装修-店招' || scope.row.module === '页面制作--店招' ? scope.row.url : scope.row.url[0]" alt="">
                        </div>
                    </div>
                    <div v-else>
                        未提交
                    </div>
                </template>
            </el-table-column>
            <el-table-column align="center">
                <template slot="header" slot-scope="{column, $index}">
                    <span>{{level === 2 ? `评分（最高分为100）` : `评分（店招最高分为${maxScore.dz_score}，海报最高分为${maxScore.poster_score}）`}}</span>
                </template>
                <template slot-scope="scope">
                    <template v-if="scope.row.status !== '未提交'">
                        <template v-if="level === 2">
                            <el-input-number v-if="scope.row.module === '网店开设'"
                                             v-model="scope.row.score" style="width: 88px;margin-right: 16px" :min="0" :max="100" :controls="false" :precision="1" ></el-input-number>
                        </template>
                        <template v-else>
                            <el-input-number v-if="scope.row.module === '用户页面装修-店招' || scope.row.module === '页面制作--店招'"
                                             v-model="scope.row.score" style="width: 88px;margin-right: 16px" :min="0" :max="maxScore.dz_score" :controls="false" :precision="1" ></el-input-number>
                            <el-input-number v-else
                                             v-model="scope.row.score" style="width: 88px;margin-right: 16px" :min="0" :max="maxScore.poster_score" :controls="false" :precision="1" ></el-input-number>
                        </template>
                    </template>
                    <el-link type="warning" v-if="scope.row.status === '未批改'" @click="correctGrade(scope.row)" :underline="false">批改</el-link>
                    <el-link type="warning" v-if="scope.row.status === '已批改'" @click="correctGrade(scope.row)" :underline="false">重新批改</el-link>
                    <span v-if="scope.row.status === '未提交'">该学生暂未提交无法评分</span>
                </template>
            </el-table-column>
            <el-table-column align="center" label="操作">
                <template slot-scope="scope">
                    <template v-if="level === 2">
                        <el-link v-if="scope.row.status !== '未提交'" type="primary" :underline="false"
                                 class="view-detail" @click="lookStoreDetail(scope.row)">查看详情
                        </el-link>
                    </template>
                    <template v-else>
                        <el-link v-if="scope.row.status !== '未提交'" type="primary" :underline="false"
                                 class="view-detail" @click="lookDetail(scope.row)">查看详情
                        </el-link>
                    </template>
                    <el-link type="primary" :underline="false" class="view-detail" @click="lookStandard(scope.row)">
                        评分标准
                    </el-link>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination class="pager-center" style="margin: 20px;text-align: center"
                       :current-page="listPages.currentPageNum"
                       :page-size="listPages.eachPageNum"
                       :total="listPages.total"
                       layout="prev, pager, next, jumper"
                       @current-change="listCurrentChange">
        </el-pagination>
        <el-dialog title="评分标准"
                   :visible.sync="gradingDialog" width="830px"
                   custom-class="green-dialog goods-score-dialog">
            <div class="upload-file-content">
                <el-table :data="gradingList" class="customTable goods-text-table" style="width: 100%; flex: 1;"
                          height="1%"
                          :header-cell-style="{fontWeight: 'normal', height: '40px', color: '#333', background: '#F2F3FA',fontSize: '14px'}"
                          :cell-style="{fontSize: '14px',color: '#333333',height: '40px'}">
                    <el-table-column prop="index" label="序号" align="center"></el-table-column>
                    <el-table-column prop="content" label="考核内容" align="left"></el-table-column>
                    <el-table-column prop="require" label="考核要点" align="left"></el-table-column>
                    <el-table-column prop="standard" label="评分标准" align="left"></el-table-column>
                    <el-table-column prop="score" label="配分" align="center"></el-table-column>
                </el-table>
            </div>
        </el-dialog>
        <el-dialog title="查看详情" :visible.sync="detailDialog" width="830px" custom-class="green-dialog">
            <div v-show="imgType === 'dz'">
                <img :src="imgSrc" alt="" width="100%">
            </div>

            <div v-show="imgType === 'banner'">
                <div v-swiper:mySwiper="bannerOption" class="computer-banner">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide" :key="bannerItem.id" v-for="bannerItem in imgList">
                            <img :src="bannerItem ? bannerItem : require('../../../assets/images/noimg_banner.png')" class="banner-img">
                        </div>
                        <div class="swiper-slide" v-if="imgList.length === 0">
                            <div class="no-img">
                                <img :src="require('../../../assets/images/noimg_banner.png')" class="banner-img">
                                <span style="color: #999999;margin-top: 10px;">还未上传海报,点击上传</span>
                            </div>
                        </div>
                    </div>
                    <div class="swiper-pagination" slot="pagination"></div>
                    <div class="banner-swiper-btn-prev">
                        <i class="iconfont">&#xe680;</i>
                    </div>
                    <div class="banner-swiper-btn-next">
                        <i class="iconfont">&#xe602;</i>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
    import 'swiper/css/swiper.css'

    import {teacheropDzPosterList, teacheropDzPosterScore, teacheropShopDecorationList} from '@/utils/apis'

    export default {
        name: "Index",
        directives: {
            swiper: directive
        },
        data(){
            return {
                role:Number(localStorage.getItem('role')) || '',
                studentList:[],
                listPages:{
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
                maxScore: {
                    dz_score: 0,
                    poster_score: 0,
                },
                examId:this.$route.query.examId || '',
                status:1,
                stateList:[
                    {
                        value:1,
                        label:'未批改'
                    },
                    {
                        value:2,
                        label:'已批改'
                    },
                    {
                        value:3,
                        label:'未提交'
                    },
                ],
                gradingDialog:false,
                gradingList:[],

                detailDialog: false,
                imgType: '',
                imgSrc: '',
                imgList: [],
                bannerOption: {
                    autoplay: {
                        delay: 3000,
                        disableOnInteraction: false
                    },
                    initialSlide: 1,
                    autoHeight: true,
                    loop: true,
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true,
                    },
                    navigation: {
                        nextEl: '.banner-swiper-btn-next',
                        prevEl: '.banner-swiper-btn-prev',
                    },
                    observer: true,
                    observeParents:true,
                },
                level: Number(this.$route.query.level) || null
            }
        },
        mounted() {
            if (this.level === 2) {
                this.getList();
            } else {
                this.getStudentList();
            }
        },
        methods:{
            getList() {
                let params = {
                    exam_id: this.examId,
                    status: this.status,
                    page: this.listPages.currentPageNum,
                    limit: this.listPages.eachPageNum,
                }
                teacheropShopDecorationList(params).then(res => {
                    this.listPages.total = res.data.total;
                    this.gradingList = res.data.scoring_criteria
                    let list = res.data.list
                    if (list.length > 0) {
                        if (list[0].status === '未批改') {
                            this.studentList = list.map(item => {
                                return {
                                    score: void 0,
                                    module: item.module,
                                    status: item.status,
                                    user_id: item.user_id,
                                }
                            })
                        } else {
                            this.studentList = list;
                        }
                    } else {
                        this.studentList = list;
                    }
                }).catch(err => {})
            },
            getStudentList(){
                let params = {
                    exam_id: this.examId,
                    status: this.status,
                    page: this.listPages.currentPageNum,
                    limit: this.listPages.eachPageNum,
                }
                teacheropDzPosterList(params).then((res)=>{
                    this.listPages.total = res.data.total;
                    this.maxScore = res.data.score;
                    this.gradingList = res.data.scoring_criteria

                    let list = res.data.list
                    if (list.length > 0) {
                        if (list[0].status === '未批改') {
                            this.studentList = list.map(item => {
                                return {
                                    score: void 0,
                                    begin_time: item.begin_time,
                                    module: item.module,
                                    name: item.name,
                                    status: item.status,
                                    url: item.url,
                                    user_id: item.user_id,
                                }
                            })
                        } else {
                            this.studentList = list;
                        }
                    } else {
                        this.studentList = list;
                    }
                }).catch((err)=>{
                    console.log('err',err)
                })
            },
            // 批改&重新批改
            correctGrade(row){
                if (row.score === void 0) {
                    return this.$message.warning('请填写分值')
                }
                let formData = new FormData();
                formData.append('exam_id', this.examId);
                formData.append('user_id', row.user_id);
                formData.append('score', row.score);

                if (this.level === 2) {
                    formData.append('type', '3')
                } else {
                    if (row.module === '用户页面装修-店招' || row.module === '页面制作--店招') {
                        formData.append('type', '1')
                    } else {
                        formData.append('type', '2')
                    }
                }
                teacheropDzPosterScore(formData).then((res)=>{
                    this.$message.success(res.msg);
                    if (this.level === 2) {
                        this.getList()
                    } else {
                        this.getStudentList()
                    }
                }).catch((err)=>{
                    console.log('err',err)
                })
            },
            // 评分标准
            lookStandard(row){
                this.gradingDialog = true;
            },
            // 查看详情
            lookDetail(row){
                if (row.module === '用户页面装修-店招' || row.module === '页面制作--店招') {
                    this.imgType = 'dz'
                    this.imgSrc = row.url
                } else {
                    this.imgType = 'banner'
                    this.imgList = row.url
                }
                this.detailDialog = true
            },
            lookStoreDetail(row) {
                let routeData = this.$router.resolve({
                    path: '/stuStore',
                    query: {
                        exam_id: this.examId,
                        user_id: row.user_id
                    }
                })
                window.open(routeData.href, "_blank");
            },
            selectState(){
                this.listPages.currentPageNum = 1;
                if (this.level === 2) {
                    this.getList()
                } else {
                    this.getStudentList()
                }
            },
            listCurrentChange(val){
                this.listPages.currentPageNum = val;
                if (this.level === 2) {
                    this.getList()
                } else {
                    this.getStudentList()
                }
            },
        }
    }
</script>

<style scoped lang="scss">
    .theory-list{
        padding: 0px 20px;
        box-sizing: border-box;
        height: 100%;
        display: flex;
        flex-direction: column;
        .theory-list-header{
            display: flex;
            align-items: center;
            span{
                line-height: 1;
            }
        }
    }
    .goods-wrapper {
        display: inline-flex;
        align-items: center;
        .goods-cover {
            width: 100px;
            height: 40px;
            display: flex;
            background: #F6F6F6;
            img  {
                max-width: 100%;
                max-height: 100%;
                margin: auto;
            }
        }
        .goods-name {
            width: 1%;
            flex: 1;
            margin-left: 10px;
            font-size: 16px;
        }
    }

    .computer-banner {
        width: 100%;
        /*height: 436px;*/
        height: 395px;
        .banner-img {
            display: block;
            /*width: calc(100% - 180px);*/
            /*max-height: 630px;*/
            max-width: 100%;
            max-height: 100%;
            margin: 29px auto 0;
        }
        ::v-deep .swiper-wrapper {
            height: calc(100% - 20px) !important;
            .swiper-slide {
                display: flex;
                justify-content: center;
                align-items: center;
                height: calc(100% - 20px) !important;
            }
        }
        ::v-deep .swiper-pagination {
            position: relative;
            bottom: 0;
        }
        ::v-deep .swiper-pagination-bullets {
            .swiper-pagination-bullet {
                width: 30px;
                height: 3px;
                background: #2C405F;
                border-radius: 0;
            }
        }
        .no-img {
            width: 264px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .banner-swiper-btn-prev,
        .banner-swiper-btn-next {
            width: 90px;
            position: absolute;
            top: 0;
            bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 10;
            opacity: .5;
            cursor: pointer;
            img {
                display: block;
                width: 20px;
            }
            &:hover {
                opacity: 1;
            }
        }
        .banner-swiper-btn-prev {
            left: 0;
            i {
                color: #BFBFBF;
                font-size: 30px;
            }
        }
        .banner-swiper-btn-next {
            right: 0;
            i {
                color: #BFBFBF;
                font-size: 34px;
            }
        }
    }
</style>